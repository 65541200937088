/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private _mainService: MainService, public global: GlobalService, private router: Router) { }

  // ItemService

  getItems(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  };

  getItemsByID(id, locale, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Items/' + id + '?localeid=' + locale, key);
  };

  saveItem(model, key): Promise<any> {
    if (model.ItemId) {
      return this._mainService.callWebserviceHttpPut('Items', model, key);
    } else {
      delete model.ItemId;
      return this._mainService.callWebserviceHttpPost('Items', model, key);
    }
  };
  saveItemImage(obj, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('ItemImage', obj, key);
  }

  deleteItem(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Items/' + id, key);
  }

  getItemImage(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Items/' + id + '/Image', key);
  };

  getItemHistory(id, sort, sortField, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('ItemHistory?itemId=' + id + '&orderBy=' + sortField + '&orderDir=' + sort, key);
  }

  getAnswerHistory(answerid, itemid, sort, sortField, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('AnswerHistory?answerId=' + answerid + '&itemId=' + itemid + '&orderBy=' + sortField + '&orderDir=' + sort, key);
  }

  getFilteredItems(linked, id, domain, key): Promise<any> {
    const query = 'Items?domain=' + domain + '&nonLinked=' + linked + '&ignoreItemId=' + id;
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  //AnswerService


  getAnswers(id, text, localeid, child, ignoreId, sort, sortField, key): Promise<any> {
    if (ignoreId) {
      const query = 'Items/' + id + '/Answers' + '?search=' + encodeURIComponent(text) + '&localeId=' + encodeURIComponent(localeid) + '&answergroup=' + child + '&ignoreAnswerId=' + ignoreId + '&orderBy=' + sortField + '&orderDir=' + sort;
      return this._mainService.callWebserviceHttpGet(query, key);
    } else {
      const query = 'Items/' + id + '/Answers' + '?search=' + encodeURIComponent(text) + '&localeId=' + encodeURIComponent(localeid) + '&answergroup=' + child + '&orderBy=' + sortField + '&orderDir=' + sort;
      return this._mainService.callWebserviceHttpGet(query, key);
    }

  };

  getAnswersForRequiredId(id, text, localeid, child, ignoreId, sort, sortField, key): Promise<any> {
    if (ignoreId) {
      const query = 'Items/Answers' + '?answergroup=false' + '&pageSize=' + '100' + '&search=' + encodeURIComponent(text) + '&localeId=' + encodeURIComponent(localeid) + '&ignoreAnswerId=' + ignoreId + '&orderBy=' + sortField + '&orderDir=' + sort;
      return this._mainService.callWebserviceHttpGet(query, key);
    } else {
      const query = 'Items/Answers' + '?answergroup=false' + '&pageSize=' + '100' + '&search=' + encodeURIComponent(text) + '&localeId=' + encodeURIComponent(localeid) + '&orderBy=' + sortField + '&orderDir=' + sort;
      return this._mainService.callWebserviceHttpGet(query, key);
    }

  };
  getAnswer(id, key): Promise<any> {
    const query = 'Answers/' + id;
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  getAnswertype(key): Promise<any> {
    const query = 'Answer/Types';
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  deleteAnswer(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Answers/' + id, key);
  }
  getAnswerFile(id, key): Promise<any> {
    const query = 'Answers/' + id + '/File';
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  saveAnswer(model, key): Promise<any> {
    if (model.AnswerId) {
      return this._mainService.callWebserviceHttpPut('Answers', model, key);
    } else {
      delete model.AnswerId;
      return this._mainService.callWebserviceHttpPost('Answers', model, key);
    }
  }

  saveAnswerFile(obj, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('AnswerFile', obj, key);
  }

  //FlexFieldService

  getFlexFieldDefinition(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getflexdefinition(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('FlexFieldDefinitions/' + id, key);
  }

  getFlexFieldTypes(key): Promise<any> {
    const query = 'FlexFieldTypes?search=';
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  deleteFlexfield(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('FlexFieldDefinitions/' + id, key);
  }

  saveFlexFieldDefinition(model, key): Promise<any> {
    if (model.FlexFieldDefinitionId) {
      return this._mainService.callWebserviceHttpPut('FlexFieldDefinitions', model, key);
    } else {
      delete model.editMode;
      return this._mainService.callWebserviceHttpPost('FlexFieldDefinitions', model, key);
    }

  }

  // checklistService
  getChecklistDefinitions(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getChecklistDefinition(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('FlexFieldDefinitions/' + id, key);
  }

  getChecklistTypes(key): Promise<any> {
    const query = 'FlexFieldTypes?search=';
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  deleteChecklist(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('FlexFieldDefinitions/' + id, key);
  }

  saveChecklistDefinition(model, key): Promise<any> {
    if (model.FlexFieldDefinitionId) {
      return this._mainService.callWebserviceHttpPut('FlexFieldDefinitions', model, key);
    } else {
      delete model.editMode;
      return this._mainService.callWebserviceHttpPost('FlexFieldDefinitions', model, key);
    }

  }

  exportCheckList(userId, checkListId, locale, itemId, startDate, endDate, key) {
    const query = 'CheckListExport?userId=' + userId + '&checkListId=' + checkListId + '&itemId=' + itemId + '&startDate=' + startDate + '&endDate=' + endDate + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  getCheckListReport(userId, checkListId, locale, itemId, startDate, endDate, sort, sortField, key) {
    const query = 'CheckListReport?userId=' + userId + '&checkListId=' + checkListId + '&itemId=' + itemId + '&startDate=' + startDate + '&endDate=' + endDate + '&localeId=' + locale + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  getCheckListData(checkListId, key) {
    const query = 'CheckListData/' + checkListId;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  deleteCheckListData(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('CheckListData/' + id, key);
  }

  //UserService
  getUsers(query, key): Promise<any> {
    // let query = "Users?search=" + encodeURIComponent(text);
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  };

  getUserById(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Users/' + id, key);
  }

  deleteUser(id, password, key): Promise<any> {
    return this._mainService.callWebserviceHttpDeleteWithPassword('Users/' + id, password, key);
  }

  saveUser(obj, password, key): Promise<any> {
    if (obj.UserId) {
      // delete obj.Password;
      return this._mainService.callWebserviceHttpPutWithPassword('Users?isInvite=0', obj, password, key);
    } else {
      delete obj.UserId;
      return this._mainService.callWebserviceHttpPostWithPassword('Users', obj, password, key);
    }
  }

  inviteUser(obj, password, key): Promise<any> {
    if (obj.UserId) {
      // delete obj.Password;
      return this._mainService.callWebserviceHttpPutWithPassword('Users?isInvite=1', obj, password, key);
    } else {
      delete obj.UserId;
      return this._mainService.callWebserviceHttpPostWithPassword('Users/Invitation', obj, password, key);
    }
  }

  getRoles(lang, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Roles?localeId=' + lang, key);
  }

  exportUserList(searchText, sortField, sort, locale, key) {
    const query = 'Users/Export?search=' + encodeURIComponent(searchText) + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);
  }


  //BeaconService

  getBeacons(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  };

  getBeaconById(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Beacons/' + id, key);
  }

  saveBeacon(obj, key): Promise<any> {
    if (obj.BeaconId) {
      return this._mainService.callWebserviceHttpPut('Beacons', obj, key);
    } else {
      delete obj.BeaconId;
      return this._mainService.callWebserviceHttpPost('Beacons', obj, key);
    }
  }

  deleteBeacon(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Beacons/' + id, key);
  }

  //SettingService

  getSettings(text, sort, sortField, key, showSystemSettings = false): Promise<any> {
    const query = 'Settings?search=' + encodeURIComponent(text) + '&showSystemSettings=' + showSystemSettings + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  deleteSetting(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Settings/' + id, key);
  }

  saveSetting(model, key): Promise<any> {
    if (model.SettingId) {
      return this._mainService.callWebserviceHttpPut('Settings', model, key);
    } else {
      return this._mainService.callWebserviceHttpPost('Settings', model, key);
    }
  }

  getSettingLogo(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Setting/Logo', key);
  }

  saveSettingLogo(model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Setting/Logo', model, key);
  }

  //UserActivity

  getUserActivity(userId, locationId, itemId, answerId, startDate, endDate, page, pageSize, sort, sortField, locale, key): Promise<any> {

    const query = 'UserActivity?userId=' + userId + '&locationId=' + locationId + '&itemId=' + itemId + '&answerId=' + answerId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeid=' + locale;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  exportUserActivity(userId, locationId, itemId, answerId, startDate, endDate, timezoneOffset, sort, sortField, key, locale): Promise<any> {
    const query = 'UserActivityExport?userId=' + userId + '&locationId=' + locationId + '&itemId=' + itemId + '&answerId=' + answerId + '&startDate=' + startDate + '&endDate=' + endDate + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeid=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  exportIncidentReport(userId, incidentReportStatusId, userGroupId, urgency, startDate, endDate, text, sort, sortField, timezoneOffset, page, pageSize, key, locale): Promise<any> {
    const query = 'IncidentReports/Export?incidentReportStatusId=' + incidentReportStatusId + '&urgency=' + urgency + '&senderUserid=' + userId + '&search=' + encodeURIComponent(text) + '&userGroupId=' + userGroupId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);

  }

  getUserLoginReport(userId, startDate, endDate, page, pageSize, sort, sortField, key) {
    const query = 'UserLoginInfos?userId=' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  exportUserLoginReport(userId, startDate, endDate, timezoneOffset, sort, sortField, key, locale) {
    const query = 'UserLoginInfoExport?userId=' + userId + '&startDate=' + startDate + '&endDate=' + endDate + '&localeId=' + locale + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  getTestReport(status, userId, showAll, page, pageSize, sort, sortField, locale, userFlexfields, key) {
    let query = 'KnowledgeTests/Report?status=' + status + '&userId=' + userId + '&showAll=' + showAll + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeId=' + locale;
    //adding flexfield params only if flexfield exists
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  exportTestReport(status, userId, showAll, timezoneOffset, sort, sortField, key, locale) {
    const query = 'KnowledgeTests/Report/Export?status=' + status + '&userId=' + userId + '&showAll=' + showAll + '&localeId=' + locale + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

    //User Progress Report
    getUserProgressReport(searchText, userId, userGroupId, status, page, pageSize, orderDir, orderBy, isFromDashboard, key, locale): Promise<any> {
      const query = 'KnowledgeTests/ProgressReport?searchText=' + encodeURIComponent(searchText)
        + '&userId=' + userId
        + '&userGroupId=' + userGroupId
        + '&userStatus=' + status
        + '&page=' + page
        + '&pageSize=' + pageSize
        + '&orderBy=' + orderBy
        + '&orderDir=' + orderDir
        + '&isFromDashboard=' + isFromDashboard
        + '&localeId=' + locale
        ;
      return this._mainService.callWebserviceHttpGetByPaging(query, key);
    }

    exportUserProgressReport(searchText, userId, userGroupId, status, sort, sortField, isFromDashboard, key, locale): Promise<any> {
      const query = 'KnowledgeTests/ProgressReport/Export?searchText=' + encodeURIComponent(searchText)
      + '&userId=' + userId
      + '&userGroupId=' + userGroupId
      + '&userStatus=' + status
      + '&orderBy=' + sortField
      + '&orderDir=' + sort
      + '&isFromDashboard=' + isFromDashboard
      + '&localeId=' + locale
      ;
      return this._mainService.callWebserviceHttpGet(query, key);
    }

    //Domain overview report                          
    getDomainoverview(searchText,page, pageSize, orderDir, orderBy, key, locale): Promise<any> {
      const query = 'DomainOverviewReport?searchText=' + encodeURIComponent(searchText)
        + '&page=' + page
        + '&pageSize=' + pageSize
        + '&orderBy=' + orderBy
        + '&orderDir=' + orderDir
        + '&localeId=' + locale
        ;
      return this._mainService.callWebserviceHttpGetByPaging(query, key);
    }
                        
    exportDomainoverview(searchText, orderDir, orderBy, key, locale): Promise<any> {
      const query = 'DomainOverviewReport/Export?searchText=' + encodeURIComponent(searchText)
        + '&orderBy=' + orderBy
        + '&orderDir=' + orderDir
        + '&localeId=' + locale
        ;
      return this._mainService.callWebserviceHttpGet(query, key);
    }

  //Browseroverviewreport
  getBrowserOverviewReport(timePeriodFilter, page, pageSize, sort, sortField, key, locale): Promise<any> {
    const query = 'Users/UserAgents?timePeriod=' + timePeriodFilter
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&orderBy=' + sortField
      + '&orderDir=' + sort
      + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);

  }

  exportBrowserOverviewReport(timePeriodFilter, sort, sortField, key, locale): Promise<any> {
    const query = 'Users/UserAgents/Export?timePeriod=' + timePeriodFilter
      + '&orderBy=' + sortField
      + '&orderDir=' + sort
      + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);

  }

  //Performanceoverviewreport
  getPerformanceOverviewReport(weekFrom, weekTo, year, questionCategoryId, page, pageSize, orderDir, orderBy, key, locale): Promise<any> {
    const query = 'Users/PerformanceReport?weekFrom=' + weekFrom
      + '&weekTo=' + weekTo
      + '&year=' + year
      + '&questionCategoryId=' + questionCategoryId
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&orderBy=' + orderBy
      + '&orderDir=' + orderDir
      + '&localeId=' + locale;
      return this._mainService.callWebserviceHttpGetByPaging(query, key);
    }

  exportPerformanceOverviewReport(weekFrom, weekTo, year, questionCategoryId, orderDir, orderBy, key, locale): Promise<any> {
    const query = 'Users/PerformanceReport/Export?weekFrom=' + weekFrom
      + '&weekTo=' + weekTo
      + '&year=' + year
      + '&questionCategoryId=' + questionCategoryId
      + '&orderBy=' + orderBy
      + '&orderDir=' + orderDir
      + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);
  }


  //phishing

  getPhishingBatches(search, orderBy, page, pageSize, locale, orderDir, key): Promise<any> {
    const query = 'PhishingBatches?search=' + search
      + '&orderBy=' + orderBy
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&localeId=' + locale
      + '&orderDir=' + orderDir;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

        //Dashboard
        getDashBoardView(localeCode): Promise<any> {
          return this._mainService.callWebserviceHttpGet(`DashBoardView?localeCode=`+localeCode , localStorage.authenticationKey);
        }

  getPhishingBatchesDetail(id, localeCode, key): Promise<any> {
    const query = 'PhishingBatches/' + id + '?localeCode=' + localeCode;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  updatePhishingBatchesDetail(id, obj, key): Promise<any> {
    if (id === '0' || id == null) {
      return this._mainService.callWebserviceHttpPostFile('PhishingBatches/Import', obj, key);
    } else {
      return this._mainService.callWebserviceHttpPutFile('PhishingBatches/' + id, obj, key);
    }
  }

  deletePhishingBatches(ids, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('PhishingBatches/' + ids, key);
  }

  //flexfield for user detail page

  getUserFlexFields(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('UserFlexFieldDefinitions', key);
  }


  updateUserFlexFields(obj, key): Promise<any> {
    return this._mainService.callWebserviceHttpPut('UserFlexFieldDefinitions',obj, key);
  }
  //PhishingBatchesReport
  getPhishingBatchesReport(searchText, locale, users, batchDescription, orderBy, page, pageSize, orderDir, userFlexfields, key): Promise<any> {
    let query = 'PhishingBatches/Report?search=' + encodeURIComponent(searchText)
      + '&localeId=' + locale
      + '&users=' + users
      + '&batchDescription=' + encodeURIComponent(batchDescription)
      + '&orderBy=' + orderBy
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&orderDir=' + orderDir;

    //adding flexfield params only if flexfield exists
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  exportPhishingBatchesReport(searchText, locale, users, batchDescription, orderBy, orderDir, userFlexfields, key): Promise<any> {
    let query = 'PhishingBatches/Export?search=' + encodeURIComponent(searchText)
    + '&localeId=' + locale
    + '&users=' + users
    + '&batchDescription=' + encodeURIComponent(batchDescription)
    + '&orderBy=' + orderBy
    + '&orderDir=' + orderDir;
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  //EmailNotificationsReport
  getEmailNotificationsReport(search, localeId, users,notificationTemplateId, orderBy, orderDir, page, pageSize,dateTimeFrom,dateTimeTo, userFlexfields, key): Promise<any> {
    let query = 'Notifications/EmailNotificationsReport?search=' + encodeURIComponent(search)
      + '&localeId=' + localeId
      + '&users=' + users
      + '&notificationTemplateId=' + notificationTemplateId
      + '&orderBy=' + orderBy
      + '&orderDir=' + orderDir
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&dateTimeFrom=' + dateTimeFrom
      + '&dateTimeTo=' + dateTimeTo;

      //adding flexfield params only if flexfield exists
  if (userFlexfields && userFlexfields !== undefined) {
    for (const property in userFlexfields) {
      if (userFlexfields.hasOwnProperty(property)) {
        if (Array.isArray(userFlexfields[property])) {
          userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
        }
        else {
          userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
        }
        query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
      }
    }
  }
      return this._mainService.callWebserviceHttpGetByPaging(query, key);
    }
  getKnowledgeTestQuestionsReport(searchText,userId ,questionCategory , orderBy, orderDir, page, pageSize,dateTimeFrom,dateTimeTo, localeId,userFlexfields, key): Promise<any> {
    let query = 'UserKnowledgeTestQuestionReport?search=' + encodeURIComponent(searchText)
      + '&userId=' + userId
      + '&questionCategoryId=' + questionCategory
      + '&orderBy=' + orderBy
      + '&orderDir=' + orderDir
      + '&page=' + page
      + '&pageSize=' + pageSize
      + '&startDate=' + dateTimeFrom
      + '&endDate=' + dateTimeTo
      + '&localeId=' + localeId;

      //adding flexfield params only if flexfield exists
  if (userFlexfields && userFlexfields !== undefined) {
    for (const property in userFlexfields) {
      if (userFlexfields.hasOwnProperty(property)) {
        if (Array.isArray(userFlexfields[property])) {
          userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
        }
        else {
          userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
        }
        query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
      }
    }
  }
      return this._mainService.callWebserviceHttpGetByPaging(query, key);
    }



  //UserFlexFields data
  getUserFlexFieldsData(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('UserFlexFieldDefinitions/FlexFields', key);
  }



  //LocationService

  getLocations(key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Locations', key);
  }

  getTranslation(lang, key) {
    return this._mainService.callWebserviceHttpGet('Translations?locale=' + lang, key);
  }

  // Translations
  getTranslations(lang, checklistDataId, isCategory): Promise<any> {
    const params = {
      authenticationkey: localStorage.authenticationKey,
    };
    return this._mainService.callWebserviceHttpGet('Translations?locale=' + lang + '&checklistDataId=' + checklistDataId + '&isCategory=' + isCategory + '&isTranslationCode=1', params.authenticationkey);
  }

  // Questions
  getQuestions(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getQuestion(id, locale, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`Questions/${id}?localeId=${locale}`, key);
  }

  saveQuestions(model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Questions', model, key);
  }

  deleteQuestion(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Questions/' + id, key);
  }

  // Questions Categories
  getQuestionCategories(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  saveQuestionCategory(model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('QuestionCategories', model, key);
  }

  deleteQuestionCategory(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('QuestionCategories/' + id, key);
  }

  // question report
  getQuestionReports(userId, questionCategoryId, startDate, endDate, userFlexfields, key,page,pageSize): Promise<any> {
    let query = 'UserQuestions?userId=' + userId
      + '&questionCategoryId=' + questionCategoryId
      + '&startDate=' + startDate
      + '&endDate=' + endDate
      + '&page=' + page
      + '&pageSize=' + pageSize;
    //adding flexfield params only if flexfield exists
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getUserQuestionsExport(userId, questionCategoryId, startDate, endDate, timezoneOffset, key, localeId): Promise<any> {
    const query = 'UserQuestionExport?userId=' + userId + '&questionCategoryId=' + questionCategoryId + '&startDate=' + startDate + '&endDate=' + endDate + '&localeId=' + localeId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset);
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // answer report
  getUserQuestionReports(userId, questionCategoryId, startDate, endDate, page, pageSize, sort, sortField, userFlexfields, key): Promise<any> {
    let query = 'UserQuestionAnswers?userId=' + userId + '&questionCategoryId=' + questionCategoryId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort;
    //adding flexfield params only if flexfield exists
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getUserQuestionAnswerExport(userId, questionCategoryId, startDate, endDate, timezoneOffset, sort, sortField, key, localeId,): Promise<any> {
    const query = 'UserQuestionAnswerExport?userId=' + userId + '&questionCategoryId=' + questionCategoryId + '&startDate=' + startDate + '&endDate=' + endDate + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeId=' + localeId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset);
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // userGroups
  getUserGroups(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }
  deleteUserGroup(ids, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('UserGroups/' + ids, key);
  }

  // userGroupDetail
  getuserGroupByID(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('UserGroups/' + id, key);
  };

  saveUserGroupById(value, model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('UserGroups?' + 'allUsers=' + value, model, key);
  };

  // QuestionRatings Reports
  // getQuestionRatingsReports(questionId,questionCategoryId, localeId, userId, startDate, endDate, key): Promise<any> {
  //   let query = "UserRatings?questionId=" + questionId + "&questionCategoryId=" + questionCategoryId + + "&localeId=" + localeId +"&startDate=" + startDate + "&endDate=" + endDate + "&userId=" + userId;
  //   return this._mainService.callWebserviceHttpGetByPaging(query, key);
  // }

  getQuestionRatingsReports(questionId, questionCategoryId, localeId, userId, startDate, endDate, page, pageSize, sort, sortField, key): Promise<any> {
    const query = 'UserRatings/Questions?isExtendedDetail=1&userId=' + userId + '&questionId=' + questionId + '&questionCategoryId=' + questionCategoryId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }


  getAverageQuestionRatingsReports(questionId, questionCategoryId, localeId, userId, startDate, endDate, page, pageSize, sort, sortField, key): Promise<any> {
    const query = 'UserRatings/Questions?isExtendedRating=1&userId=' + userId + '&questionId=' + questionId + '&questionCategoryId=' + questionCategoryId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  //averagequestion rating
  getAverageQuestionRatingsReportsExport(categoryId, questionId, timezoneOffset, sort, sortField, localeId, key): Promise<any> {
    const query = 'UserRatingExport/Questions?isExtendedRating=1&questionId=' + questionId + '&localeId=' + localeId + '&questionCategoryId=' + categoryId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  //averagequestionratingdetail export
  getAverageQuesRatingDetailExport(questionId, localeId, userId, startDate, endDate, timezoneOffset, sort, sortField, key): Promise<any> {
    const query = 'UserRatings/Questions/Export?questionId=' + questionId + '&localeId=' + localeId + '&isExtendedRating=0&startDate=' + startDate + '&endDate=' + endDate + '&userId=' + userId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  getQuestionRatingsReportsExport(questionId, questionCategoryId, localeId, userId, startDate, endDate, timezoneOffset, sort, sortField, key): Promise<any> {
    const query = 'UserRatingExport/Questions?isExtendedDetail=1&userId=' + userId + '&questionId=' + questionId + '&questionCategoryId=' + questionCategoryId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // Question Ratings Detail
  getQuestionRatings(questionId, userId, localeId, startDate, endDate, page, pageSize, key): Promise<any> {
    const query = 'UserRatings?isExtendedDetail=1&questionId=' + questionId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&userId=' + userId;;
    return this._mainService.callWebserviceHttpGet(query, key);
  }


  // UserRatings Reports
  getUserRatingsReports(answerId, localeId, userId, startDate, endDate, itemId, sort, sortField, key): Promise<any> {
    const query = 'UserRatings/Report?answerId=' + answerId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&userId=' + userId + '&itemId=' + itemId + '&isExtendedRating=0' + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getUserRatingsReportsExport(answerId, localeId, userId, startDate, endDate, timezoneOffset, itemId, sort, sortField, key): Promise<any> {
    const query = 'UserRatings/Report/Export?answerId=' + answerId + '&localeId=' + localeId + '&isExtendedRating=0&startDate=' + startDate + '&endDate=' + endDate + '&userId=' + userId + '&itemId=' + itemId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // Average Ratings
  getAverageRatingsReports(itemId, answerId, localeId, startDate, endDate, page, pageSize, sort, sortField, key): Promise<any> {
    const query = 'UserRatings?answerId=' + answerId + '&itemId=' + itemId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&isExtendedRating=1' + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getAverageRatingsReportsExport(localeId, startDate, endDate, timezoneOffset, itemId, answerId, sort, sortField, key): Promise<any> {
    const query = 'UserRatingExport?localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&itemId=' + itemId + '&answerId=' + answerId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // Answer Ratings Detail
  getAnswerRatings(answerId, userId, localeId, startDate, endDate, page, pageSize, sort, sortField, key): Promise<any> {
    const query = 'UserRatings?isExtendedDetail=1&answerId=' + answerId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + pageSize + '&userId=' + userId + '&orderBy=' + sortField + '&orderDir=' + sort;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getAnswerRatingsExport(userId, localeId, startDate, endDate, key): Promise<any> {
    const query = 'UserRatingDetailExporgett?answerId=' + userId + '&localeId=' + localeId + '&startDate=' + startDate + '&endDate=' + endDate;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  // questionnaire
  getQuestionnaire(randomKey, localeCode) {
    const query = 'Questionaire/' + randomKey + '/' + localeCode;
    return this._mainService.callWebserviceHttpGetNoHeader(query);
  }

  updateUserQuestions(obj, key): Promise<any> {
    const query = 'UserQuestions';
    return this._mainService.callWebserviceHttpPost(query, obj, key);
  }

  //twilio register
  registerUser(obj, key): Promise<any> {
    const query = 'Twilio/Register';
    return this._mainService.callWebserviceHttpPost(query, obj, key);
  }

  //domains
  getDomains(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getDomainByID(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Domains/' + id, key);
  };

  saveDomain(data, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Domains', data, key);
  }

  deleteDomain(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Domains/' + id, key);
  }

  //messages
  getMessages(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getSentMessage(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  sendMessage(obj, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Messages', obj, key);
  }


  // Translations
  getSystemTranslations(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getTranslationByID(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Translations/' + id, key);
  };


  saveTranslations(model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Translations', model, key);
  }


  updateTranslations(model, key): Promise<any> {
    return this._mainService.callWebserviceHttpPut('Translations', model, key);
  }


  deleteTransalation(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Translations/' + id, key);
  }


  getDashboardInfos(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getDashBoardInfo(id, localeid): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DashBoardInfos/${id}?localeId=${localeid}`, localStorage.authenticationKey);
  }

  deleteDashboardInfos(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('DashboardInfos/' + id, key);
  }

  saveDashboardInfo(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost('DashBoardInfos', obj, localStorage.authenticationKey);
  }

  getIncidentReport(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }
  getIncidentReportDetail(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`IncidentReports/${id}`, localStorage.authenticationKey);
  }

  saveIncidentReport(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPut('IncidentReports', obj, localStorage.authenticationKey);
  }

  getIncidentReportStatus(locale): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`IncidentReportStatuses?localeId=${locale}`, localStorage.authenticationKey);
  }
  getIncidentImpact(locale): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`IncidentImpact?localeId=${locale}`, localStorage.authenticationKey);
  }

  getIncidentCount(): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`IncidentReports/Count`, localStorage.authenticationKey);
  }

  ping(code): Promise<any> {
    if (code) {
      return this._mainService.callWebserviceHttpGet(`Ping`, code);
    } else {
      return this._mainService.callWebserviceHttpGet(`Ping`, localStorage.authenticationKey);
    }
  }

  getIncidentCountInterval() {
    clearTimeout(this.global.incidentCountTimer);
    this.global.incidentCountTimer = setInterval(() => {
      this.getIncidentCount().then(
        (data) => {
          if (data) {
            localStorage.setItem('incidentCount', data.count ? data.count : 0);
            this.global.reportedIncidentCount = data.count ? data.count : 0;
          }
        },
        (error) => {
          if (error && error.status == 401) {
            this.router.navigate(['/login'], { replaceUrl: true });
          }
        }
      );
    }, 10000);

  }
  getHelpText(): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`HelpTexts`, localStorage.authenticationKey);
  };

  saveHelpText(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost('HelpTexts', obj, localStorage.authenticationKey);
  }
  getDomainSetting(domain): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Domains/' + domain + '/Setting', '');
  }
  updateToken(token): Promise<any> {
    return this._mainService.callWebserviceHttpPatch('Users', token, localStorage.authenticationKey);
  }

  getNotificationList(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }
  getNotificationDetail(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`NotificationTemplates/${id}`, localStorage.authenticationKey);
  }
  updateNotificationDetail(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPut('NotificationTemplates', obj, localStorage.authenticationKey);
  }
  oganizeKnowledgeTest(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost('KnowledgeTests/OrganizeTest', obj, localStorage.authenticationKey);
  }
  verify2faLinkedSend(data): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Reset2Fa', data, '');
  }

  getLoginCode(obj, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost('Users/GenerateLoginCode', obj, localStorage.authenticationKey);
  }

  //Decision tree apis
  getDecisionTreeList(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getDecisionTree(id, localeid): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DecisionTrees/${id}/Tree?localeId=${localeid}`, localStorage.authenticationKey);
  }

  getDecisionTreeDetail(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DecisionTrees/${id}`, localStorage.authenticationKey);
  }

  saveDecisionTree(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost('DecisionTrees', obj, localStorage.authenticationKey);
  }

  deleteDecisionTree(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('DecisionTrees/' + id, key);
  }

  getConclusions(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getDecisionTreeConclusion(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DecisionTreesConclusions/${id}`, localStorage.authenticationKey);
  }

  saveDecisionTreeConclusions(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost('DecisionTreesConclusions', obj, localStorage.authenticationKey);
  }

  deleteDecisionTreeConclusion(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('DecisionTreesConclusions/' + id, key);
  }

  getDecisionTreeQuestion(decisionTreeId, id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DecisionTrees/${decisionTreeId}/Questions/${id}`, localStorage.authenticationKey);
  }

  saveDecisionTreeQuestions(decisionTreeId, obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`DecisionTrees/${decisionTreeId}/Questions`, obj, localStorage.authenticationKey);
  }

  deleteDecisionTreeQuestion(decisionTreeId, id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete(`DecisionTrees/${decisionTreeId}/Questions/${id}`, key);
  }

  getDecisionTreeAnswer(decisionTreeId, id): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`DecisionTrees/${decisionTreeId}/Answers/${id}`, localStorage.authenticationKey);
  }

  saveDecisionTreeAnswers(decisionTreeId, obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`DecisionTrees/${decisionTreeId}/Answers`, obj, localStorage.authenticationKey);
  }

  deleteDecisionTreeAnswer(decisionTreeId, id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete(`DecisionTrees/${decisionTreeId}/Answers/${id}`, key);
  }

  syncDomain(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`Domains/Syncdomain`, obj, localStorage.authenticationKey);
  }

  copyDomain(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`Domains/Copydomain`, obj, localStorage.authenticationKey);
  }

  getUserOverViewReport(searchText, userTypeId, page, pageSize, sort, sortField, locale, key) {
    const query = 'Users/Overview' + '?search=' + encodeURIComponent(searchText) + '&roleId=' + userTypeId + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }
  exportUserOverViewReport(searchText, userTypeId, timezoneOffset, sort, sortField, locale, key) {
    const query = 'Users/Overview/Export?search=' + encodeURIComponent(searchText) + '&roleId=' + userTypeId + '&timezoneOffset=' + encodeURIComponent(timezoneOffset) + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeId=' + locale;
    return this._mainService.callWebserviceHttpGet(query, key);
  }

  updateUserLocale(data, userId): Promise<any> {
    return this._mainService.callWebserviceHttpPatch('Users/' + userId + '/Locale', data, localStorage.authenticationKey);
  }

  sendTestmail(id, lang): Promise<any> {
    return this._mainService.callWebserviceHttpGet('NotificationTemplates/' + id + '/Test?localeId=' + lang, localStorage.authenticationKey);
  }

  getSecretKey(id): Promise<any> {
    return this._mainService.callWebserviceHttpGet('Notifications/' + id, '');
  }

  //get password prompt OTP

  getPasswordPromptOTP(id, obj): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`Users/${id}/OTP`, obj, localStorage.authenticationKey);
  }

  //scorm

  getScormReport(userId, itemId, userGroupId, answerId, showAll, page, pageSize, sort, sortField, locale,userFlexfields, key): Promise<any> {

    let query = 'Scorms/Report?userId=' + userId +  '&itemId=' + itemId  +  '&userGroupId=' + userGroupId+ '&answerId=' + answerId + '&showAll=' + showAll + '&page=' + page + '&pageSize=' + pageSize + '&orderBy=' + sortField + '&orderDir=' + sort + '&localeid=' + locale;
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  exportScormReport(userId, itemId, userGroupId, answer, showAll, timezoneOffset, sort, sortField,userFlexfields, key, locale) {
    let query = 'Scorms/Report/Export?answerId=' + answer
    + '&userId=' + userId
    + '&itemId=' + itemId
    + '&userGroupId=' + userGroupId
    + '&showAll=' + showAll
    + '&localeId=' + locale
    + '&timezoneOffset='+ encodeURIComponent(timezoneOffset)
    + '&orderBy=' + sortField
    + '&orderDir=' + sort;
    if (userFlexfields && userFlexfields !== undefined) {
      for (const property in userFlexfields) {
        if (userFlexfields.hasOwnProperty(property)) {
          if (Array.isArray(userFlexfields[property])) {
            userFlexfields[property] = userFlexfields[property].map(el => encodeURIComponent(el));
          }
          else {
            userFlexfields[property] = encodeURIComponent(userFlexfields[property]);
          }
          query = query.concat('&' + property.toLowerCase() + '=' + userFlexfields[property]);
        }
      }
    }
    return this._mainService.callWebserviceHttpGet(query, key);
  }


  getScorm(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }

  getScormDetail(id, localeid): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`Scorms/${id}?localeId=${localeid}`, localStorage.authenticationKey);
  }

  saveScormData(id, obj): Promise<any> {
    if (id == '0' || id == null) {
      return this._mainService.callWebserviceHttpPost('Scorms', obj, localStorage.authenticationKey);
    } else {
      return this._mainService.callWebserviceHttpPut(`Scorms/${id}`, obj, localStorage.authenticationKey);
    }
  }
  saveScormFile(id, obj, locale): Promise<any> {
    return this._mainService.callWebserviceHttpPostFile(`Scorms/${id}/File?localeId=${locale}`, obj, localStorage.authenticationKey);
  }

  deleteScormData(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpDelete('Scorms/' + id, key);
  }


  // getRequiresAnswerId(id, text, localeid, child, ignoreId, sort, sortField, key):Promise<any> {
  //   let query = "Answers?itemId=" + id + "&isScorm=true" + "&search=" + encodeURIComponent(text) + "&localeId=" + encodeURIComponent(localeid) +"&nonChild=" + child + "&ignoreAnswerId="+ ignoreId + "&orderBy="+sortField+"&orderDir="+ sort;
  //   return this._mainService.callWebserviceHttpGet(query, key);
  // }

  getRequiresAnswerId(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGetByPaging(query, key);
  }
  resetKnowledgeTestCount(userId, password, key) {

    return this._mainService.callWebserviceHttpPutWithPassword(`Users/${userId}` + '/ResetKnowledgeTestCount', '', password, key);
  }


  importUsersFile(obj, key): Observable<any> {
    return this._mainService.callWebserviceHttpPostFileObserveProgress('Users/Import', obj, key);
  }

  sampleUserImportFile(): Promise<any> {

    return this._mainService.callWebserviceHttpGet('Users/Import/File', localStorage.authenticationKey);

  }


  getSupportUser(id, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`Login/${id}`, '', key);
  };
  doDomainLogin(domainId, key): Promise<any> {
    return this._mainService.callWebserviceHttpPost(`Login/Domain/${domainId}`, '', key);
  }

  getEmailInviteConfig(): Promise<any> {

    return this._mainService.callWebserviceHttpGet(`EmailInvites/Configuration`, localStorage.authenticationKey);
  }

  postEmailInviteConfig(obj): Promise<any> {

    return this._mainService.callWebserviceHttpPost(`EmailInvites/Configuration`, obj, localStorage.authenticationKey);
  }
  getSwitchDomainsName(query, key): Promise<any> {
    return this._mainService.callWebserviceHttpGet(query, key);
  };

  getEnvironments(): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`Environments`, localStorage.authenticationKey);
  }


  //Branding
  getBranding(): Promise<any> {
    return this._mainService.callWebserviceHttpGet(`Domains/${localStorage.domainId}/Branding`, localStorage.authenticationKey);
  }

  saveBranding(obj): Promise<any> {
    return this._mainService.callWebserviceHttpPut(`Domains/${localStorage.domainId}/Branding`, obj, localStorage.authenticationKey);
  }
}
